/* Container style */
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f7f7f7;
}

/* Heading style */
.heading {
    font-size: 24px;
    text-align: center;
    color: #ff4949;
    margin-bottom: 30px;
    font-weight: bold;
}

/* Card styling for user details */
.userDetails {
    background: #fff;
    border-radius: 10px;
    padding: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 100%;
    margin-bottom: 20px;
}

/* Styling for each detail */
.userDetails p {
    font-size: 16px;
    margin: 12px 0;
    color: #333;
    line-height: 1.5;
}

/* Bold styling for labels */
.userDetails strong {
    font-weight: 600;
    color: #333;
}

/* Submit button styling */
.submitButton {
    display: block;
    width: 100%;
    padding: 12px 0;
    margin-top: 20px;
    background-color: #ff4949;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    transition: background-color 0.3s;
}

.submitButton:hover {
    background-color: #e14040;
}
.locationIcon {
    color: blue;
    cursor: pointer;
    margin-left: 8px;
    font-size: 20px;
}
