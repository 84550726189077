.footerContact {
  background-color: #27ae60;
  padding: 40px 0;
  color: #fff;
}
.footerContact h1 {
  font-size: 40px;
}
footer {
  background-color: #ff4949;
  padding: 50px 0;
  color: #fff;
}
footer .container {
  display: grid;
  grid-template-columns: 10fr 2fr;
  grid-gap: 20px;
}
footer img {
  width: 150px;
}
footer h2 {
  font-weight: 500;
}
footer p {
  color: white;
  margin: 20px 0;
}
footer input {
  background-color: #fff;
  padding: 17px;
  width: 100%;
  border-radius: 5px;
}
footer h3 {
  font-weight: 500;
  margin-bottom: 30px;
}
footer ul {
  display: block;
}
footer ul li {
  display: block;
  color: white;
  margin-bottom: 20px;
}
.copyright {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: #ff4949;
  color: white;
  border-top: 1px solid white;
  
  .left-text {
    text-align: left;
  }
  
  .right-text {
    text-align: right;
  }
}
.footer-link {
  color: inherit; 
  text-decoration: none;
  
}

.app-store-play-store-icons {
  display: flex;
  align-items: center;
}

.app-store-play-store-icons a {
  margin-right: 10px; 
}
@media screen and (max-width: 400px) {
  footer .container {
    display: block;
  }
}
