.location .box {
  position: relative;
  border-radius: 5px;
}
.location img {
  border-radius: 5px;
}

.locationGrid{
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  gap: 10px; /* Adjust the gap as needed */
}

.centered-text {
  position: absolute;
  top: 70%;
  left: 30%;
  /* transform: translate(-50%, -50%); */
  /* display: flex; */
  color:white;
  align-items: center;
  justify-content: center;
  /* Additional styles (like background, padding) can be added here */
}
.location .overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 250px;
  width: 100%;
  color: #fff;
  z-index: 222;
}
.location .overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 340px;
  height: 210px;
  /* background: rgb(15 28 47 / 30%); */
  z-index: -1;
  margin: 20px;
  border-radius: 5px;
}
.location h5 {
  font-size: 18px;
  font-weight: 500;
}
.location label {
  color: #fff;
  margin-right: 20px;
  opacity: 0.8;
}
@media screen and (max-width: 800px) {
  .location .overlay::after {
    width: 280px;
    height: 170px;
  }

  .centered-text{
    font-size: 5px;
  }

}
