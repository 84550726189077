.hero {
  background-image: url("../../../../public/images/banner.jpg");
  background-position: center;
  background-size: cover;
  min-height: 100vh; 
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  padding-bottom: 20px;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(250, 249, 246, 0.8);
  z-index: 0;
}

.hero .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  padding: 20px;
  position: relative;
  z-index: 1;
}

.hero .content {
  flex: 1;
  padding: 20px;
  margin-bottom: 70px; /* Adjust bottom margin for more spacing */
}

.hero .content h1 {
  color: #ff4949;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero .content h2 {
  color: #ff4949;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.hero .content p {
  /* color: gray; */
  font-size: 1.5rem;
  margin-bottom: 0;
  margin-top: 2rem;
}

.hero .download-buttons {
  display: flex;
  gap: 20px;
  margin-top: 40px;
}
.hero .android-download-image {
  min-height: 3rem;
  min-width: 10rem;
}

.hero .ios-download-image {
  min-height: 3rem;
  min-width: 10rem;
}

.hero .download-buttons a {
  text-decoration: none;
}

.hero .download-buttons img {
  width: 150px; /* Adjust image width */
  height: auto;
  cursor: pointer;
}

.hero .image img {
  max-width: 80%;
  max-height: 80vh;
  height: auto;
  width: auto;
  border-radius: 10px;
}

@media screen and (max-width: 800px) {
  .hero {
    height: auto;
    padding: 50px 20px;
  }

  .hero .container {
    flex-direction: column;
  }

  .hero .content, .hero .image {
    width: 100%;
    text-align: center;
  }

  .hero .download-buttons {
    flex-direction: row; /* Ensure buttons remain in a row */
    gap: 10px;
    justify-content: center; /* Center the buttons on smaller screens */
  }

  .hero .download-buttons img {
    width: 120px; /* Adjust image width */
  }

  .hero .image {
    display: none;
  }

  .hero .content h2 {
    color: #ff4949;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 400px) {
  .hero {
    height: auto;
    background-size:contain;
    background-repeat:inherit;
    /* padding: 50px 20px; */
  }

  .hero .content, .hero .image {
    width: 100%;
    text-align: center;
  }

  .hero .download-buttons {
    flex-direction: row; 
    justify-content: center;
  }

  .hero .download-buttons img {
    width: 100px; /* Adjust image width */
  }

  .hero .content h2 {
    color: #ff4949;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  .hero .content h1 {
    color: #ff4949;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .hero .content p {
    /* color: gray; */
    font-size: 1rem;
    margin-bottom: 0;
    margin-top: 1rem;
  }
}
