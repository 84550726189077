.contact form div {
  display: flex;
}
.contact textarea {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: 5px;
  margin-bottom: 20px;
}
.contact input {
  width: 100%;
  margin-bottom: 20px;
  margin-right: 10px;
}
.contact-form {
  flex: 1;
  padding-right: 20px;
}

.contact-container {
  display: flex;
  justify-content: space-between;
  
}
.contact-image {
  flex: 1;
  margin-top: 30px;
}
.contact-image img {
  width: 100%;
  height: auto;
}

.contact-text{
  border: 1px solid lightgrey;
  padding: 6px;
}

.contact-title{
  margin-bottom: 10px;
}
.shadow{
  padding: 10px;
}
.submitButton{
  /* padding-top: 2em; */
  margin-bottom: 2em;
}
@media screen and (max-width: 768px) {
  .contact h4 {
    width: 100%;
    margin-bottom: 30px;
  }
  .contact form div {
    flex-direction: column;
  }
  .contact-form {
    flex-direction: column;
    padding-right: 0px; /* Adjust as needed */
  }
  .contact-form input {
    width: 100% !important;
  }
  .contact-image{
    display: none;
  }
  .submitButton{
    width: 100%;
    margin-bottom: 2em;
  }
}
